import React from "react";
import { graphql } from "gatsby";

import Job from "../components/Job";

import metaData from "../languages/zh/metaData";
import dictionary from "../languages/zh/job";

const pageJobQuery = graphql`
  query PageJobZh($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        date
        title
        location
        requirement
        links {
          name
          url
        }
      }
    }
  }
`;

const PageJobZh = (props) => {
  const { frontmatter } = props.data.markdownRemark;
  return (
    <Job
      lang="zh"
      metaData={metaData}
      dictionary={dictionary}
      data={frontmatter}
    />
  );
};

export default PageJobZh;
export { pageJobQuery };
