import React from "react";
import { FontAwesomeIcon as FAI } from "@fortawesome/react-fontawesome";
import "../plugins/fontawesome";

import reviseIsoString from "../lib/reviseIsoString";

import * as Grid from "../modules/grid";
import { Layout } from "../modules/layout";
import { Banner } from "../modules/banner";
import { Divider } from "../modules/divider";
import { Button } from "../modules/button";
import { ClickItem } from "../modules/clickItem";

const CONTACT = "career@ravelinfo.com";

const Job = (props) => {
  const { lang, metaData, dictionary, data } = props;

  const _redirectJobs = (url) => {
    window.location.href = `${window.location.origin}/${lang}/jobs`;
  };
  const _openLink = (url) => {
    window.open(url);
  };
  const _copyToClipboard = () => {
    const copyText = document.getElementById("contact");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
  };

  return (
    <Layout lang={lang} metaData={metaData} pagename={dictionary.pagename}>
      <Banner title={dictionary.pagename} />
      <div className="page page-job">
        <input
          id="contact"
          className="job_hiddenInput"
          readOnly
          value={CONTACT}
        />
        <section className="job_section">
          <div className="section_bg" />
          <div className="section_pagrname">{dictionary.pagename}</div>
          <Grid.Row justify="center">
            <Grid.Col col={10} md={6} className="section_head">
              <h1 className="head_title">{dictionary.opening}</h1>
              <Button
                type="text"
                className="head_button"
                iconLeft={
                  <FAI
                    className="button_icon"
                    icon={["fas", "caret-square-left"]}
                    size="lg"
                    fixedWidth
                  />
                }
                onClick={_redirectJobs}
              >
                {dictionary.back}
              </Button>
              <Divider className="head_divider" />
              <div className="head_text">
                <strong>{dictionary.date}</strong>
                {reviseIsoString(data.date).whole}
              </div>
              <div className="head_text">
                <strong>{dictionary.contact}</strong>
                <ClickItem onClick={_copyToClipboard}>
                  {CONTACT}
                  <Button type="link">
                    <FAI icon={["fas", "copy"]} fixedWidth />
                  </Button>
                </ClickItem>
              </div>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row justify="center" spacing={false}>
            <Grid.Col col={10} md={6}>
              <h2 className="job_position">{data.title}</h2>
              <p>
                <strong>{dictionary.location}</strong>
                {data.location}
              </p>
              <p>
                <strong>{dictionary.requirement}</strong>
                <br />
                {data.requirement}
              </p>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row justify="center" spacing={false}>
            <Grid.Col col={10} md={6} className="text-right">
              {data.links.map((link) => {
                return (
                  <Button
                    className="section_button"
                    color="yellow"
                    size="sm"
                    key={link.url}
                    onClick={() => {
                      _openLink(link.url);
                    }}
                  >
                    {link.name}
                  </Button>
                );
              })}
            </Grid.Col>
          </Grid.Row>
        </section>
      </div>
    </Layout>
  );
};

export default Job;
